import React, { useEffect, useState } from "react";
/*import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  Redirect
} from "react-router-dom";*/
//import Routes from "./routes";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { FANCY_FONT, MENU_FONT, JACKSON_FONT } from "../constants";
import { Link } from "gatsby";
import Image from "gatsby-image";

import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 90%;

  width: 100%;
  border-top: 1px solid #dfdfdf;
  padding-top: 20px;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  align-items: center;
`;

const LimitWidth = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100%;
  max-width: 1024px;
  width: 100%;
`;

const Page = styled.div`
  flex: 1;
  min-height: 100%;
  padding: 20px;
`;

const Spacer = styled.div`
  height: 50px;
`;

const FancyHeading = styled.span`
  font-family: ${FANCY_FONT};
  //font-size: 600%;
  color: black;
  //-webkit-text-stroke: 1px #FFE5E5;
`;

const JacksonHeading = styled.span`
  font-family: ${JACKSON_FONT};
  //font-size: 600%;
  color: black;
  //-webkit-text-stroke: 1px #FFE5E5;
`;

const NameHeading = styled.span`
  font-size: 500%;

  /*@media (max-width: 480px) {
    font-size: 380%;
  }*/

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    font-size: 250%;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    font-size: 250%;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    font-size: 500%;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    font-size: 500%;
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    font-size: 500%;
  }


`;

const NotNameHeading = styled.span`
  font-size: 300%;

  /*@media (max-width: 480px) {
    font-size: 240%;
  }*/

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    font-size: 150%;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    font-size: 150%;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    font-size: 300%;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    font-size: 300%;
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    font-size: 300%;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.center && "align-items: center;"}
`;

const Nav = styled.div`
  background-color: #89CFF0;//#ffe5e5;
  font-family: ${MENU_FONT};
  font-size: 150%;
  border-top: 1px solid black;
  border-bottom: 1px solid black;

  & > ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    list-style-type: none;
    margin: 0;
    padding: 0;

    & > li {
      margin: 0;
      display: block;
      padding-right: 15px;
      padding-left: 15px;
      padding-top: 5px;
      padding-bottom: 5px;

      &:hover {
        color: #960;
      }

      & > a {
        text-decoration: none;
        color: unset;

        &:visited {
          color: unset;
        }
      }
    }
  }
`;

const CurrentNav = styled.li`
  border-bottom: 2px solid black;
`;

const TitleAvatar = styled.div`
  border-radius: 100%;
  overflow: hidden;
  //background-color: red;
  text-align: center;
  //padding: 0px;
  //margin: 0px;
  
  
  width: 200px;
  height: 200px;


  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 480px) {
    display: none;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 480px) {
    display: block;
    height: 120px;
    width: 120px;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    display: block;
    height: 120px;
    width: 120px;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    display: block;
    height: 200px;
    width: 200px;
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    display: block;
    height: 200px;
    width: 200px;
  }

`;

const AvatarImg = styled.img`
  display: block;
  width: 100%;
`;

const FlexHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  ${(props) => props.center && "justify-content: space-around;"}
  ${(props) => props.center && "align-items: center;"}
`;

const App = (props) => {
  const data = useStaticQuery(graphql`
    query ChrisAndQuynhQuery {
      chrisandquynh: file(absolutePath: { regex: "/chris-and-quynh.jpg/" }) {
        childImageSharp {
          fixed(width: 200, height: 200, quality: 95) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      jackson: file(absolutePath: { regex: "/jackson.jpg/" }) {
        childImageSharp {
          fixed(width: 200, height: 200, quality: 95) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const links = [
    {
      navLink: "/",
      navLinkLabel: "Blog",
      selected: false
    },
    {
      navLink: "/jackson",
      navLinkLabel: "Jackson Photos",
      selected: false
    },
    {
      navLink: "/wedding",
      navLinkLabel: "Wedding Photos",
      selected: false
    },
    /*{
      navLink: "/test",
      navLinkLabel: "Test",
    },*/
  ];

  const [linkState, setLinkState] = useState(links);

  useEffect(() => {
    console.log(props)
    const newLinkState = [];
    linkState.forEach(link => newLinkState.push({...link, selected: (props.location || window.location).pathname === link.navLink}))
    setLinkState(newLinkState);
    console.log(linkState);
  },[])


 

  return (
    <Container>
      <LimitWidth>
        <Spacer />
        <FlexHorizontal center>
          <TitleAvatar>
            <AvatarImg
              src={data?.chrisandquynh?.childImageSharp?.fixed.src}
              srcSet={data?.chrisandquynh?.childImageSharp?.fixed.srcSet}
              alt="Chris and Quynh"
            />
          </TitleAvatar>
          <Flex center>
            <FancyHeading>
              <NameHeading>Chris</NameHeading>
              <NotNameHeading>{" and "}</NotNameHeading>
              <NameHeading>Quynh</NameHeading>
            </FancyHeading>
            <JacksonHeading>
              <NotNameHeading>{" + "}</NotNameHeading>
            </JacksonHeading>
            <JacksonHeading>
              <NotNameHeading>Jackson</NotNameHeading>
            </JacksonHeading>
          </Flex>
          <TitleAvatar>
            <AvatarImg
              src={data?.jackson?.childImageSharp?.fixed.src}
              srcSet={data?.jackson?.childImageSharp?.fixed.srcSet}
              alt="Jackson"
            />
          </TitleAvatar>
        </FlexHorizontal>

        <Spacer />
        <Nav>
          <ul>
            {linkState.map((item, index) => {
              if (item.selected)
              {
              console.log(`item selected ${JSON.stringify(item)}`)
                return (
                  <CurrentNav key={index}>
                    <Link to={item.navLink}>{item.navLinkLabel}</Link>
                  </CurrentNav>
                );
              }
              else
                return (
                  <li key={index}>
                    <Link to={item.navLink}>{item.navLinkLabel}</Link>
                  </li>
                );
            })}
          </ul>
        </Nav>
        <Page>{props.children}</Page>
      </LimitWidth>
      <Footer>{`Copyright © ${new Date().getFullYear()} by Chris and Quynh`}</Footer>
    </Container>
  );
};

export default App;
