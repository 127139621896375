//import React from 'react';

export const FONT = "'Roboto', sans-serif";
export const FANCY_FONT = "'Parisienne', sans-serif";
export const MENU_FONT = "'Damion', sans-serif";
export const JACKSON_FONT = "'Gochi Hand'"

/*font-family: 'Roboto', sans-serif;
font-family: 'Tangerine', cursive;
font-family: 'Parisienne', cursive;
font-family: 'Damion', cursive;
font-family: 'Italianno', cursive;*/